"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.teamsCardsLoader = void 0;
const access_1 = require("../../../../common/constants/access");
const appConstants_1 = require("../../../constants/appConstants");
const companyQuery_1 = require("../../../queries/companyQuery");
const teamQuery_1 = require("../../../queries/teamQuery");
const userQuery_1 = require("../../../queries/userQuery");
const teamsCardsLoader = async ({ queryClient }) => {
    const teamIdQuery = (0, teamQuery_1.selectedTeamIdQuery)('selectedTeamID');
    const verifyUserAccessQuery = (0, userQuery_1.getVerifyUserAccessQuery)(access_1.composites.WRITE_CARD_SOURCES);
    const teamId = await queryClient.ensureQueryData(teamIdQuery);
    const userData = await queryClient.ensureQueryData(userQuery_1.userQuery);
    const teams = await queryClient.ensureQueryData((0, teamQuery_1.teamsQuery)());
    const team = await queryClient.ensureQueryData((0, teamQuery_1.teamQuery)({ teamId: teamId?.[0] || '' }));
    const hasAccess = await queryClient.ensureQueryData({
        queryKey: verifyUserAccessQuery.queryKey,
        queryFn: verifyUserAccessQuery.queryFn,
    });
    const companyProperties = await queryClient.ensureQueryData((0, companyQuery_1.companyPropertiesQuery)(userData.companyAccountId, appConstants_1.companyPropertiesConstants));
    return {
        teamId,
        userData,
        teams,
        team,
        hasAccess,
        companyProperties,
    };
};
exports.teamsCardsLoader = teamsCardsLoader;
