"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.colors = void 0;
exports.colors = {
    blue: {
        100: '#DAE3FF',
        200: '#89A4FF',
        300: '#5071FF',
        400: '#0A37F0',
        500: '#0E36CF',
        600: '#1334AD',
        700: '#002099',
    },
    green: {
        100: '#CBF7E0',
        200: '#B4F1C9',
        300: '#92E2A0',
        400: '#73D47E',
        500: '#60B76E',
        600: '#4D985B',
        700: '#398447',
    },
    grey: {
        100: '#F0F3F5',
        200: '#E7ECEF',
        300: '#CDD7DB',
        400: '#9FB1BB',
        500: '#889EA9',
        600: '#6A8492',
        700: '#566A76',
        800: '#43545E',
        900: '#303C42',
    },
    magenta: {
        100: '#ECD7E4',
        200: '#DAAEC9',
        300: '#B96497',
        400: '#992160',
        500: '#791848',
        600: '#601035',
        700: '#4C0021',
    },
    violet: {
        100: '#DAD5F9',
        200: '#A99EF1',
        300: '#6451E9',
        400: '#5A49DB',
        500: '#4F40BC',
        600: '#44369C',
        700: '#302288',
    },
    red: {
        100: '#F1C1C4',
        200: '#E89A9E',
        300: '#D96267',
        400: '#CC3537',
        500: '#AB2C2E',
        600: '#862121',
        700: '#720D0D',
    },
    yellow: {
        100: '#FCF3DA',
        200: '#FAEBBA',
        300: '#F7D57A',
        400: '#F4BF45',
        500: '#F4B03F',
        600: '#EC9235',
        700: '#D87E21',
    },
};
