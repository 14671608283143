"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const react_1 = require("react");
const react_router_1 = require("react-router");
const appConstants_1 = require("../../../../constants/appConstants");
const strings_1 = require("../../../../constants/strings");
const codingQuery_1 = require("../../../../queries/codingQuery");
const companyQuery_1 = require("../../../../queries/companyQuery");
const requestProfilesQuery_1 = require("../../../../queries/requestProfilesQuery");
const sessionQuery_1 = require("../../../../queries/sessionQuery");
const supportQuery_1 = require("../../../../queries/supportQuery");
const Root_1 = require("../../../../router/Root");
const zustandStore_1 = require("../../../../zustandStore");
const DEFAULT_PROFILE = 'default';
const ENABLE_OUT_OF_OFFICE_FORWARDING = 'ENABLE_OUT_OF_OFFICE_FORWARDING';
const SupportFeatures = () => {
    const { serverVersion, profiles, userData, requestProfiles, configId, workflowConfigs, providerMap, providerMappingConfigs, companyProperties, } = (0, react_router_1.useLoaderData)();
    const { features, requestProfile, workflowConfig, providerMapping, setRequestProfile, setCompanyFeature, setNotification, setWorkflowConfig, setProviderMapping, properties, setCompanyProperty, } = (0, zustandStore_1.useZustand)(state => ({
        features: state?.company?.features,
        workflowConfig: state.support.workflowConfig,
        requestProfile: state.support.requestProfile,
        providerMapping: state.support.providerMapping,
        setRequestProfile: state.setRequestProfile,
        setWorkflowConfig: state.setWorkflowConfig,
        setCompanyFeature: state.setCompanyFeature,
        setNotification: state.setNotification,
        setProviderMapping: state.setProviderMapping,
        properties: state?.company?.properties,
        setCompanyProperty: state.setCompanyProperty,
    }));
    const isOooCompanyActive = features?.[ENABLE_OUT_OF_OFFICE_FORWARDING] === true;
    const isActivityMenuOption = typeof properties.showactivity === 'object' ? false : properties.showactivity === 'true';
    const setProfilesQuery = (0, requestProfilesQuery_1.useSetProfileForCompany)();
    const saveCompanyFeature = (0, companyQuery_1.useSaveCompanyFeatureQuery)();
    const saveCompanyProperty = (0, companyQuery_1.useSaveCompanyPropertyQuery)();
    const removeProfilesQuery = (0, requestProfilesQuery_1.useRemoveProfileForCompany)();
    const setWorkflowConfigQuery = (0, supportQuery_1.useSaveWorkflowConfigQuery)();
    const saveProviderMappingQuery = (0, codingQuery_1.useSaveProviderMappingQuery)();
    const deleteProviderMappingQuery = (0, codingQuery_1.useDeleteProviderMappingQuery)();
    const [userId, setUserId] = (0, react_1.useState)('');
    const reset2FA = (0, sessionQuery_1.useReset2FAQuery)();
    const handleReset2FA = () => {
        reset2FA.mutate(userId, {
            onSuccess: () => {
                setNotification([{ type: strings_1.notificationStrings.SUCCESS, message: '2FA has been reset successfully.' }]);
            },
            onError: () => {
                setNotification([{ type: strings_1.notificationStrings.ERROR, message: 'Failed to reset 2FA. Please try again.' }]);
            },
        });
    };
    (0, react_1.useEffect)(() => {
        setRequestProfile(requestProfiles.id);
    }, [requestProfiles]);
    (0, react_1.useEffect)(() => {
        if (companyProperties) {
            for (const [propertyKey, value] of Object.entries(companyProperties)) {
                setCompanyProperty({
                    key: propertyKey,
                    value: propertyKey === 'showactivity' ? value.toString() : value,
                });
            }
        }
    }, [companyProperties, setCompanyProperty]);
    (0, react_1.useEffect)(() => {
        setProviderMapping(providerMap || 'none');
    }, [providerMap]);
    (0, react_1.useEffect)(() => {
        setWorkflowConfig(configId);
    }, [configId]);
    const toggleOutOfOffice = toggleValue => {
        setCompanyFeature({ key: ENABLE_OUT_OF_OFFICE_FORWARDING, value: toggleValue });
        saveCompanyFeature.mutate({
            key: ENABLE_OUT_OF_OFFICE_FORWARDING,
            value: toggleValue,
        });
    };
    const toggleActivityMenu = (toggleValue) => {
        setCompanyProperty({ key: 'showactivity', value: toggleValue === 'true' ? 'false' : 'true' });
        saveCompanyProperty.mutate({
            companyId: userData.companyAccountId,
            key: appConstants_1.companyPropertiesConstants.showactivity,
            value: toggleValue === 'true' ? 'false' : 'true',
        }, {
            onSuccess: () => {
                return Root_1.queryClient.invalidateQueries({
                    queryKey: [
                        companyQuery_1.companyKeys.getProperties,
                        userData.companyAccountId,
                        { showactivity: appConstants_1.companyPropertiesConstants.showactivity },
                    ],
                });
            },
        });
    };
    const onProfileChange = profile => {
        setRequestProfile(profile);
        if (profile === DEFAULT_PROFILE) {
            removeProfilesQuery.mutate({ companyId: userData.companyAccountId }, {
                onSuccess: () => {
                    setNotification([
                        {
                            type: strings_1.notificationStrings.SUCCESS,
                            message: 'The request profile has been updated.',
                        },
                    ]);
                },
                onError: () => {
                    setNotification([
                        {
                            type: strings_1.notificationStrings.ERROR,
                            message: 'There was a problem setting the request profile. Please try again.',
                        },
                    ]);
                },
            });
        }
        else {
            setProfilesQuery.mutate({ companyId: userData.companyAccountId, profileId: profile }, {
                onSuccess: () => {
                    setNotification([
                        {
                            type: strings_1.notificationStrings.SUCCESS,
                            message: 'The request profile has been updated.',
                        },
                    ]);
                },
                onError: () => {
                    setNotification([
                        {
                            type: strings_1.notificationStrings.ERROR,
                            message: 'There was a problem setting the request profile. Please try again.',
                        },
                    ]);
                },
            });
        }
    };
    const onProviderMappingChange = mapping => {
        setProviderMapping(mapping || 'none');
        if (mapping === 'none') {
            deleteProviderMappingQuery.mutate(mapping, {
                onSuccess: () => {
                    setNotification([
                        {
                            type: strings_1.notificationStrings.SUCCESS,
                            message: 'The provider mapping has been deleted.',
                        },
                    ]);
                },
                onError: () => {
                    setNotification([
                        {
                            type: strings_1.notificationStrings.ERROR,
                            message: 'There was a problem setting the provider mapping. Please try again.',
                        },
                    ]);
                },
            });
        }
        else {
            saveProviderMappingQuery.mutate(mapping, {
                onSuccess: () => {
                    setNotification([
                        {
                            type: strings_1.notificationStrings.SUCCESS,
                            message: 'The provider mapping has been updated.',
                        },
                    ]);
                },
                onError: () => {
                    setNotification([
                        {
                            type: strings_1.notificationStrings.ERROR,
                            message: 'There was a problem setting the provider mapping. Please try again.',
                        },
                    ]);
                },
            });
        }
    };
    const onWorkflowConfigChange = workflow => {
        setWorkflowConfig(workflow);
        setWorkflowConfigQuery.mutate({ companyId: userData.companyAccountId, configId: workflow }, {
            onSuccess: () => {
                setNotification([
                    {
                        type: strings_1.notificationStrings.SUCCESS,
                        message: 'The workflow config has been updated.',
                    },
                ]);
            },
            onError: () => {
                setNotification([
                    {
                        type: strings_1.notificationStrings.ERROR,
                        message: 'There was a problem setting the workflow config. Please try again.',
                    },
                ]);
            },
        });
    };
    return ((0, jsx_runtime_1.jsxs)(material_1.Grid2, { sx: {
            display: 'flex',
            flexDirection: 'column',
            rowGap: 1,
            marginBottom: 1,
        }, children: [(0, jsx_runtime_1.jsxs)(material_1.Grid2, { size: 12, children: [(0, jsx_runtime_1.jsx)(material_1.Typography, { variant: "overline", gutterBottom: true, sx: {
                            display: 'block',
                        }, children: strings_1.SupportSettingsStrings.GO_VERSION_TEXT }), (0, jsx_runtime_1.jsx)(material_1.Typography, { children: serverVersion })] }), (0, jsx_runtime_1.jsxs)(material_1.Grid2, { size: 12, children: [(0, jsx_runtime_1.jsx)(material_1.Typography, { variant: "overline", gutterBottom: true, sx: {
                            display: 'block',
                        }, children: strings_1.SupportSettingsStrings.REQUEST_PROFILE_TEXT }), (0, jsx_runtime_1.jsx)(material_1.Select, { "data-testid": "request-profile", value: requestProfile, onChange: event => onProfileChange(event.target.value), children: profiles?.map(value => ((0, jsx_runtime_1.jsx)(material_1.MenuItem, { value: value, children: value }, value))) })] }), (0, jsx_runtime_1.jsxs)(material_1.Grid2, { size: 12, children: [(0, jsx_runtime_1.jsx)(material_1.Typography, { variant: "overline", gutterBottom: true, sx: {
                            display: 'block',
                        }, children: strings_1.SupportSettingsStrings.PROVIDER_API_MAPPING_TEXT }), (0, jsx_runtime_1.jsxs)(material_1.Select, { "data-testid": "provider-api-mapping", value: providerMapping, onChange: event => onProviderMappingChange(event.target.value), children: [providerMappingConfigs?.map(item => item.key !== 'HSBC' && ((0, jsx_runtime_1.jsx)(material_1.MenuItem, { value: item.label, children: item.label }, item.key))), (0, jsx_runtime_1.jsx)(material_1.MenuItem, { value: 'none', children: 'none' }, 'none')] })] }), (0, jsx_runtime_1.jsxs)(material_1.Grid2, { size: 12, children: [(0, jsx_runtime_1.jsx)(material_1.Typography, { variant: "overline", gutterBottom: true, sx: {
                            display: 'block',
                        }, children: strings_1.SupportSettingsStrings.WORKFLOW_CONFIG_TEXT }), (0, jsx_runtime_1.jsx)(material_1.Select, { "data-testid": "workflow-config", value: workflowConfig, onChange: event => onWorkflowConfigChange(event.target.value), children: workflowConfigs?.map(item => ((0, jsx_runtime_1.jsx)(material_1.MenuItem, { value: item.id, children: item.label }, item.id))) })] }), (0, jsx_runtime_1.jsxs)(material_1.Grid2, { size: 12, children: [(0, jsx_runtime_1.jsx)(material_1.Typography, { variant: "overline", gutterBottom: true, sx: {
                            display: 'block',
                        }, children: strings_1.SupportSettingsStrings.OUT_OF_OFFICE_TEXT }), (0, jsx_runtime_1.jsx)(material_1.FormGroup, { sx: { padding: 0 }, children: (0, jsx_runtime_1.jsx)(material_1.FormControlLabel, { sx: { padding: 0 }, control: (0, jsx_runtime_1.jsx)(material_1.Switch, { checked: isOooCompanyActive, onChange: () => toggleOutOfOffice(!features?.[ENABLE_OUT_OF_OFFICE_FORWARDING]) }), labelPlacement: "start", label: strings_1.SupportSettingsStrings.OOO_TOGGLE_TEXT }) })] }), (0, jsx_runtime_1.jsxs)(material_1.Grid2, { size: 12, children: [(0, jsx_runtime_1.jsx)(material_1.Typography, { "data-testid": "activity-text", variant: "overline", gutterBottom: true, sx: {
                            display: 'block',
                        }, children: strings_1.SupportSettingsStrings.ACTIVITY_TEXT }), (0, jsx_runtime_1.jsx)(material_1.FormGroup, { sx: { padding: 0 }, children: (0, jsx_runtime_1.jsx)(material_1.FormControlLabel, { sx: { padding: 0 }, control: (0, jsx_runtime_1.jsx)(material_1.Switch, { checked: isActivityMenuOption, onChange: () => toggleActivityMenu(properties['showactivity']) }), labelPlacement: "start", label: strings_1.SupportSettingsStrings.ACTIVITY_TOGGLE_TEXT }) })] }), (0, jsx_runtime_1.jsxs)(material_1.Grid2, { size: 12, sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: 1,
                }, children: [(0, jsx_runtime_1.jsx)(material_1.Typography, { variant: "overline", children: "Reset 2FA" }), (0, jsx_runtime_1.jsx)(material_1.TextField, { placeholder: "User ID", value: userId, onChange: e => setUserId(e.target.value), fullWidth: true, variant: "outlined", sx: { padding: 0 } }), (0, jsx_runtime_1.jsx)(material_1.Button, { variant: "contained", color: "primary", onClick: handleReset2FA, disabled: !userId, sx: {
                            width: 'fit-content',
                        }, children: "Reset 2FA" })] })] }));
};
exports.default = SupportFeatures;
