"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const TruncatedRequesterFullNameText = ({ text = '', fontSize = '12px' }) => {
    return ((0, jsx_runtime_1.jsx)(material_1.Tooltip, { title: text, children: (0, jsx_runtime_1.jsx)("div", { style: {
                fontSize,
                maxWidth: '80%',
                lineHeight: '1.5',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }, children: text }) }));
};
exports.default = TruncatedRequesterFullNameText;
