"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.walletLoader = void 0;
const react_router_1 = require("react-router");
const access_1 = require("../../../../common/constants/access");
const walletHelper_1 = require("../../../components/pages/wallet/walletHelper");
const appConstants_1 = require("../../../constants/appConstants");
const teamQuery_1 = require("../../../queries/teamQuery");
const userQuery_1 = require("../../../queries/userQuery");
const userSelectors_1 = require("../../../selectors/userSelectors");
let teams = [];
let navigateTo = '/wallet';
const walletLoader = async ({ queryClient, request, params, }) => {
    const verifyUserAccessQuery = (0, userQuery_1.getVerifyUserAccessQuery)(access_1.composites.WRITE_COMPANY);
    const teamId = await queryClient.ensureQueryData((0, teamQuery_1.selectedTeamIdQuery)('selectedTeamID'));
    const userSelectedTeamId = teamId?.[0] || '';
    const userData = await queryClient.ensureQueryData(userQuery_1.userQuery);
    const userRole = (0, userSelectors_1.isCompanyOrSupportAdmin)(userData.roles)
        ? appConstants_1.overviewConstants.COMPANY_ADMIN
        : userData.roles.find(role => role.securityGroupId === userSelectedTeamId)?.roleId || '';
    let team;
    try {
        team = await queryClient.ensureQueryData((0, teamQuery_1.teamQuery)({ teamId: teamId?.[0] || '' }));
    }
    catch (error) {
        team = { data: null, error, isError: true };
    }
    const hasAccess = await queryClient.ensureQueryData({
        queryKey: verifyUserAccessQuery.queryKey,
        queryFn: verifyUserAccessQuery.queryFn,
    });
    const paginatedWalletData = {
        count: 0,
        tasks: [],
        // we need this, so we do not disable the next pager when we have null values in tasks
        initialPaginatedWalletDataCount: 0,
    };
    let paginatedFilterApiQuery = {
        owner: '',
        teamId: '',
        status: 'ACTIVE',
    };
    const paginatedFilterApiQueryDefaultQueries = {
        status: 'ACTIVE',
        sortBy: 'CREATION_DATE',
        sortOrder: 'DESC',
    };
    switch (userRole) {
        case appConstants_1.overviewConstants.COMPANY_ADMIN: {
            //company admin can view the wallet cards of a specific user from his team
            // or all company wallet cards
            navigateTo = request.url.includes('wallet/team') && params.teamId && params.userId ? request.url : '/wallet';
            paginatedFilterApiQuery = {
                teamId: params.teamId || '',
                owner: params.userId || '',
                ...paginatedFilterApiQueryDefaultQueries,
            };
            break;
        }
        case appConstants_1.teamAdmin:
        case appConstants_1.manager: {
            paginatedFilterApiQuery = request.url.includes('wallet/team')
                ? {
                    teamId: params.teamId || teamId?.[0] || '',
                    owner: params.userId || '',
                    ...paginatedFilterApiQueryDefaultQueries,
                }
                : {
                    teamId: params.teamId || '',
                    owner: params.userId || userData.userId,
                    ...paginatedFilterApiQueryDefaultQueries,
                };
            if (userRole === appConstants_1.teamAdmin) {
                // team admin can view his cards or all team cards
                navigateTo = request.url.includes('wallet/team') ? '/wallet/team' : '/wallet/my-cards';
            }
            else {
                // a manager can view team cards and my wallet cards
                // and also an user cards but only for the cards where he is an approver
                navigateTo =
                    request.url.includes('wallet/team') && params.teamId && params.userId ? request.url : '/wallet/my-cards';
            }
            break;
        }
        default: {
            // other role shoul view only his cards(except tradeshift suport admin who can view all cards)
            navigateTo = '/wallet/my-cards';
            paginatedFilterApiQuery = {
                teamId: params.teamId || '',
                owner: params.userId || userData.userId,
                ...paginatedFilterApiQueryDefaultQueries,
            };
        }
    }
    // redirect to the correct path if the current URL does not match
    // based on user roles
    if (!request.url.endsWith(navigateTo)) {
        throw (0, react_router_1.redirect)(navigateTo);
    }
    const teamData = await queryClient.ensureQueryData((0, teamQuery_1.teamsQuery)({ state: 'active' }));
    let allMembersFromAllTeams = [];
    if (userRole === appConstants_1.overviewConstants.COMPANY_ADMIN) {
        teams = (0, walletHelper_1.buildTeamsOptions)({ teams: teamData });
        try {
            const allMembersCollections = await Promise.all(teamData.map(async (team) => {
                const teamMembers = await queryClient.ensureQueryData((0, teamQuery_1.getTeamUsersQuery)({ teamId: team.id, query: '' }));
                return teamMembers?.users || [];
            }));
            allMembersFromAllTeams = allMembersCollections.flat();
            const uniqueUserIds = new Set();
            const uniqueMembers = allMembersFromAllTeams.filter(member => {
                const isAlreadySeen = uniqueUserIds.has(member.userId);
                if (!isAlreadySeen) {
                    uniqueUserIds.add(member.userId);
                    return true;
                }
                return false;
            });
            allMembersFromAllTeams = uniqueMembers;
        }
        catch {
            allMembersFromAllTeams = [];
        }
    }
    return {
        teamId,
        userRole,
        userData,
        team,
        hasAccess,
        navigateTo,
        teams,
        paginatedWalletData,
        paginatedFilterApiQuery,
        allMembersFromAllTeams,
    };
};
exports.walletLoader = walletLoader;
