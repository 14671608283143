"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useZustand = void 0;
const middleware_1 = require("zustand/middleware");
const immer_1 = require("zustand/middleware/immer");
const traditional_1 = require("zustand/traditional");
const slices_1 = require("./slices");
const hsbcRegistrationStepsSlice_1 = require("./slices/hsbcAuth/hsbcRegistrationStepsSlice");
const storeName = { name: 'GO-FRONTEND Store' };
exports.useZustand = (0, traditional_1.createWithEqualityFn)((0, middleware_1.devtools)((0, immer_1.immer)((set) => ({
    ...(0, slices_1.sideMenuSlice)(set),
    ...(0, slices_1.companySlice)(set),
    ...(0, slices_1.userSlice)(set),
    ...(0, slices_1.headerSlice)(set),
    ...(0, slices_1.paymentSlice)(set),
    ...(0, slices_1.rolesSlice)(set),
    ...(0, slices_1.teamSlice)(set),
    ...(0, slices_1.footerSlice)(set),
    ...(0, slices_1.modalSlice)(set),
    ...(0, slices_1.asideSlice)(set),
    ...(0, slices_1.cardSourceSlice)(set),
    ...(0, slices_1.invitePeopleSlice)(set),
    ...(0, slices_1.codingListSlice)(set),
    ...(0, slices_1.providerMappingSlice)(set),
    ...(0, slices_1.codingEntrySlice)(set),
    ...(0, slices_1.peopleTableSlice)(set),
    ...(0, slices_1.notificationSlice)(set),
    ...(0, slices_1.dialogSlice)(set),
    ...(0, slices_1.teamCardSlice)(set),
    ...(0, slices_1.requestSlice)(set),
    ...(0, slices_1.pageSpinnerSlice)(set),
    ...(0, slices_1.authSlice)(set),
    ...(0, slices_1.errorSlice)(set),
    ...(0, slices_1.approvalsSlice)(set),
    ...(0, slices_1.streamSlice)(set),
    ...(0, slices_1.walletSlice)(set),
    ...(0, hsbcRegistrationStepsSlice_1.hsbcRegistrationStepsSlice)(set),
    ...(0, slices_1.settlementSlice)(set),
    ...(0, slices_1.supportSlice)(set),
    ...(0, slices_1.activitySlice)(set),
    ...(0, slices_1.passwordLoginSlice)(set),
    ...(0, slices_1.dashboardSlice)(set),
    ...(0, slices_1.registrationSlice)(set),
    ...(0, slices_1.invitationSlice)(set),
})), storeName));
