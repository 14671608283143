"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pageSpinnerInitialState = void 0;
exports.pageSpinnerSlice = pageSpinnerSlice;
exports.pageSpinnerInitialState = {
    pageSpinner: {
        isLoading: false,
    },
};
function pageSpinnerSlice(set) {
    return {
        ...exports.pageSpinnerInitialState,
        setPageLoading(isLoading) {
            set((state) => {
                state.pageSpinner.isLoading = isLoading;
                return state;
            }, false, '[setPageLoading]');
        },
    };
}
