"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTeamApprovalLimit = void 0;
const react_1 = require("react");
const appConstants_1 = require("../../../../constants/appConstants");
const strings_1 = require("../../../../constants/strings");
const companyQuery_1 = require("../../../../queries/companyQuery");
const approvalLimitValidator_1 = require("../../../../validators/approvalLimitValidator");
const zustandStore_1 = require("../../../../zustandStore");
const useTeamApprovalLimit = () => {
    const { data: requestLimitData } = (0, companyQuery_1.useGetRequestLimitQuery)();
    const [errors, setErrors] = (0, react_1.useState)({});
    const { setTeamRequestLimit, setIsTeamRequestLimitError, setIsSaveBtnDisabled } = (0, zustandStore_1.useZustand)(state => ({
        setTeamRequestLimit: state.setTeamRequestLimit,
        setIsTeamRequestLimitError: state.setIsTeamRequestLimitError,
        setIsSaveBtnDisabled: state.setIsSaveBtnDisabled,
    }));
    const validateTeamApprovalLimit = (newLimit) => {
        const error = (0, approvalLimitValidator_1.validateApprovalLimit)(newLimit, requestLimitData);
        const isValid = !error;
        if (error === approvalLimitValidator_1.validator.errors.INVALID_FORMAT) {
            setErrors(prevState => ({
                ...prevState,
                [appConstants_1.teamSettingsErrorFields.requestLimit]: strings_1.teamInfoStrings.INVALID_NUMBER_TEXT,
            }));
            setIsSaveBtnDisabled(true);
        }
        else if (error === approvalLimitValidator_1.validator.errors.EXCEEDED_AMOUNT) {
            setErrors(prevState => ({
                ...prevState,
                [appConstants_1.teamSettingsErrorFields.requestLimit]: globalThis.cardProvider.IS_AMEX_PROVIDER_SETUP
                    ? strings_1.teamInfoStrings.EXCEEDS_AMEX_APPROVAL_LIMIT_TEXT
                    : strings_1.teamInfoStrings.EXCEEDS_COMPANY_LIMIT_TEXT,
            }));
            setIsSaveBtnDisabled(true);
        }
        else {
            setErrors({});
            setIsSaveBtnDisabled(false);
        }
        setIsTeamRequestLimitError(!isValid);
        return isValid;
    };
    const changeApprovalLimit = (event) => {
        setTeamRequestLimit(event.target.value);
        validateTeamApprovalLimit(event.target.value);
    };
    return {
        errors,
        changeApprovalLimit,
    };
};
exports.useTeamApprovalLimit = useTeamApprovalLimit;
