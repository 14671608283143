"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HsbcLoadingButton = void 0;
const material_1 = require("@mui/material");
exports.HsbcLoadingButton = (0, material_1.styled)(material_1.Button)(({ theme }) => ({
    width: '100%',
    fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.default),
    '&.Mui-disabled': {
        color: 'black',
    },
}));
