"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.settingsOverviewLoader = void 0;
const appConstants_1 = require("../../../constants/appConstants");
const companyQuery_1 = require("../../../queries/companyQuery");
const userQuery_1 = require("../../../queries/userQuery");
const settingsOverviewLoader = async ({ queryClient }) => {
    const adminsQuery = userQuery_1.getUsersByRoleId;
    const companyAdmins = await queryClient.ensureQueryData({
        queryKey: adminsQuery(appConstants_1.overviewConstants.COMPANY_ADMIN).queryKey,
        queryFn: adminsQuery(appConstants_1.overviewConstants.COMPANY_ADMIN).queryFn,
    });
    const teamAdmins = await queryClient.ensureQueryData({
        queryKey: adminsQuery(appConstants_1.overviewConstants.TEAM_ADMIN).queryKey,
        queryFn: adminsQuery(appConstants_1.overviewConstants.TEAM_ADMIN).queryFn,
    });
    const user = await queryClient.ensureQueryData(userQuery_1.userQuery);
    const companyFeatures = await queryClient.ensureQueryData(companyQuery_1.companyFeaturesQuery);
    const companyProperties = await queryClient.ensureQueryData((0, companyQuery_1.companyPropertiesQuery)(user.companyAccountId, appConstants_1.companyPropertiesConstants));
    return {
        companyAdmins,
        teamAdmins,
        companyFeatures,
        companyProperties,
        user,
    };
};
exports.settingsOverviewLoader = settingsOverviewLoader;
