"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.zIndex = void 0;
/**
 * Z-index.
 * Defaults: https://mui.com/material-ui/customization/z-index/
 */
exports.zIndex = {
    drawer: 1400,
    modal: 1300,
};
