"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UI_SIDE_MENU_MOBILE_VIEW_OPEN = exports.UI_SIDE_MENU_OPEN = exports.UI_SIDE_MENU_COLLAPSED = exports.UI_SIDE_MENU = exports.UI_SVG_EMPTY_STATE = exports.UI_CLASS_PAGE_SETTINGS_NO_CODING_PANEL = exports.UI_CLASS_PAGE_SETTINGS_NO_CODING_CONTENT = exports.UI_CLASS_COMPANY_SETTINGS_DOMAIN = exports.UI_TAG_PRIMARY = exports.UI_BUTTON_MICRO = exports.UI_BUTTON_SECONDARY = exports.UI_BUTTON_PRIMARY = void 0;
exports.UI_BUTTON_PRIMARY = 'primary';
exports.UI_BUTTON_SECONDARY = 'secondary';
exports.UI_BUTTON_MICRO = 'small';
exports.UI_TAG_PRIMARY = 'ts-info';
exports.UI_CLASS_COMPANY_SETTINGS_DOMAIN = 'domain';
exports.UI_CLASS_PAGE_SETTINGS_NO_CODING_CONTENT = 'coding content';
exports.UI_CLASS_PAGE_SETTINGS_NO_CODING_PANEL = 'empty-state';
exports.UI_SVG_EMPTY_STATE = 'empty-state-illustration';
exports.UI_SIDE_MENU = 'side-menu';
exports.UI_SIDE_MENU_COLLAPSED = 'side-menu-collapsed';
exports.UI_SIDE_MENU_OPEN = 'side-menu-open';
exports.UI_SIDE_MENU_MOBILE_VIEW_OPEN = 'side-menu-mobile-view-open';
