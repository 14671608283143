"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.palette = void 0;
/**
 * Color palette. See options and defaults at https://mui.com/material-ui/customization/default-theme/
 */
exports.palette = {
    /**
     * MUI disagrees with tradeshift regarding contrast between background and foreground colors.
     * MUI defaults will calculate black text color on a tradeshift-blue background.
     * Setting the contrastThreshold instead of defining the primary.contrastText as white lets it up to MUI
     * to calculate colors and is safer when switching dark and light mode.
     * */
    contrastThreshold: 2.2,
    primary: {
        dark: 'rgb(0, 133, 204)',
        light: 'rgb(92, 203, 255)',
        main: 'rgb(0, 174, 255)',
    },
    secondary: {
        dark: 'rgb(86, 106, 118)',
        light: 'rgb(159, 177, 187)',
        main: 'rgb(136, 158, 169)',
    },
    success: {
        dark: 'rgb(57, 157, 11)',
        light: 'rgb(143, 218, 103)',
        main: 'rgb(80, 198, 16)',
    },
    warning: {
        dark: 'rgb(240, 124, 0)',
        light: 'rgb(255, 182, 92)',
        main: 'rgb(255, 140, 0)',
    },
    error: {
        dark: 'rgb(153, 0, 0)',
        light: 'rgb(213, 93, 93)',
        main: 'rgb(189, 0, 0)',
    },
};
