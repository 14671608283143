"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const SimpleButton = ({ className = '', label = '', onClick = () => ({}), icon = '', disabled = false, style = {}, size = 'large', color = 'primary', variant = 'contained', dataTestId = '', type = 'button', } = {}) => {
    return ((0, jsx_runtime_1.jsx)(material_1.Button, { id: dataTestId, "data-testid": dataTestId, className: className, type: type, style: {
            boxSizing: 'border-box',
            ...style,
        }, size: size, disabled: disabled, variant: variant, color: color, onClick: onClick, endIcon: icon || null, children: (0, jsx_runtime_1.jsx)("span", { style: {
                textTransform: 'uppercase',
                ...(globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
                    ? {
                        fontSize: '12px',
                        textTransform: 'none',
                    }
                    : {}),
            }, children: label }) }));
};
exports.default = SimpleButton;
