"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const react_1 = require("react");
const react_router_1 = require("react-router");
const strings_1 = require("../../../../constants/strings");
const userSelectors_1 = require("../../../../selectors/userSelectors");
const zustandStore_1 = require("../../../../zustandStore");
const actionsButton_1 = require("../../approvals/components/actionsButton");
const ViewAllApprovalsToggle = () => {
    const initialData = (0, react_router_1.useLoaderData)();
    const { activity, setViewAllApprovals, setActivityFilters, setSearchApprovalsValue, setSortApprovalsValue } = (0, zustandStore_1.useZustand)(state => ({
        ...state,
    }));
    const { viewAllApprovals } = activity;
    const handleToggle = (_event, checked) => {
        setViewAllApprovals(checked);
        if (checked) {
            setActivityFilters({ team: 'all', member: 'all' });
        }
        else {
            setSortApprovalsValue({ mostRecent: 'desc' });
            setSearchApprovalsValue('');
        }
    };
    const showToggle = (0, react_1.useMemo)(() => {
        if (!('user' in initialData)) {
            return false;
        }
        const isTeamOwner = (0, userSelectors_1.isTeamAdmin)(initialData.user?.roles);
        return isTeamOwner;
    }, [initialData]);
    return showToggle ? ((0, jsx_runtime_1.jsx)(actionsButton_1.ApprovalToggleLabel, { name: "view-all-approvals", control: (0, jsx_runtime_1.jsx)(material_1.Switch, { color: "primary", checked: viewAllApprovals, onChange: handleToggle }), label: strings_1.approvalsStrings.VIEW_ALL_APPROVALS_TEXT, labelPlacement: "start" })) : null;
};
exports.default = ViewAllApprovalsToggle;
