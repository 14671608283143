"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyledMenuItem = void 0;
const material_1 = require("@mui/material");
const tsGoThemeVars_1 = require("../../tsGoThemeVars");
exports.StyledMenuItem = (0, material_1.styled)(material_1.MenuItem)(() => ({
    minHeight: tsGoThemeVars_1.tsGoUnit.tsUnitTriple,
    position: 'relative',
    color: tsGoThemeVars_1.goPalette.tsGoColor.grayLightest,
    '&:hover': {
        background: 'rgba(0, 0, 0, 0.04)',
        a: {
            background: 'rgba(0, 0, 0, 0.04)',
        },
    },
    a: {
        minHeight: tsGoThemeVars_1.tsGoUnit.tsUnitTriple,
        padding: 0,
        minWidth: 0,
        position: 'relative',
        overflow: 'hidden',
        'a span:first-of-type': {
            paddingTop: tsGoThemeVars_1.tsGoUnit.tsUnit,
        },
        'a span': {
            padding: '10px 0',
        },
    },
    'a:hover': {
        cursor: 'pointer',
        color: tsGoThemeVars_1.goPalette.tsGoColor.grayLightest,
        textDecoration: 'none',
    },
    '&.active-option': {
        backgroundColor: globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP ? 'hsla(205, 20%, 18%, 1)' : 'rgba(25, 34, 38, 1)',
        overflow: 'hidden',
        '.expanded, .collapsed': {
            color: tsGoThemeVars_1.goPalette.tsGoColor.grayLightest,
        },
        ':hover': {
            backgroundColor: globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
                ? 'hsla(205, 20%, 18%, 1)'
                : 'rgba(25, 34, 38, 1)',
        },
        '&:before': {
            backgroundColor: globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
                ? tsGoThemeVars_1.goPalette.hsbcColor.primary
                : tsGoThemeVars_1.goPalette.tsGoColor.pink,
            position: 'absolute',
            width: '3px',
            content: '""',
            top: 0,
            bottom: 0,
            boxShadow: `0 0 15px 1px ${tsGoThemeVars_1.goPalette.tsGoColor.pink}`,
        },
    },
}));
