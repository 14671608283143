"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useApprovalsData = void 0;
const react_query_1 = require("@tanstack/react-query");
const react_1 = require("react");
const react_router_1 = require("react-router");
const approvalQuery_1 = require("../../../../queries/approvals/approvalQuery");
const teamQuery_1 = require("../../../../queries/teamQuery");
const userQuery_1 = require("../../../../queries/userQuery");
const userSelectors_1 = require("../../../../selectors/userSelectors");
const zustandStore_1 = require("../../../../zustandStore");
const slices_1 = require("../../../../zustandStore/slices");
const approvalHelpers_1 = require("../approvalHelpers");
const useApprovalsData = () => {
    const location = (0, react_router_1.useLocation)();
    const initialData = (0, react_router_1.useLoaderData)();
    const initialDataActivity = (0, react_router_1.useLoaderData)();
    const isActivityPage = location.pathname.includes('activity');
    const activeHeaderTab = (0, zustandStore_1.useZustand)(state => state.header.activeHeaderTab);
    const currentActiveApprovalTab = activeHeaderTab || 'pending';
    const { filters, setShowCreateFwdButtons, setShowNoActiveCardInfo, filteredList, setFilteredList, uiActivities, viewAllApprovals, } = (0, zustandStore_1.useZustand)(state => ({
        filters: state.activity.filters,
        uiActivities: state.activity.list,
        setShowCreateFwdButtons: state.setShowCreateFwdButtons,
        setDialog: state.setDialog,
        setShowNoActiveCardInfo: state.setShowNoActiveCardInfo,
        filteredList: state.activity.filteredList,
        setFilteredList: state.setFilteredList,
        viewAllApprovals: state.activity.viewAllApprovals,
    }));
    const { data: currentUser } = (0, react_query_1.useQuery)({
        ...userQuery_1.userQuery,
        initialData: initialData.user,
    });
    const { data: currentTeamId } = (0, react_query_1.useQuery)({
        ...(0, teamQuery_1.selectedTeamIdQuery)('selectedTeamID'),
        initialData: initialData.teamId,
    });
    const showAllApprovals = (initialDataActivity &&
        'user' in initialDataActivity &&
        (0, userSelectors_1.isCompanyOrSupportAdmin)(initialDataActivity.user?.roles || [])) ||
        viewAllApprovals;
    const { data: team, isSuccess } = (0, teamQuery_1.useGetTeamQuery)({ teamId: currentTeamId?.[0] || '' });
    const cardSources = team?.sourceCards?.filter(card => card?.status === 'ACTIVE') || [];
    const { data: approvalsData } = (0, react_query_1.useQuery)((0, approvalQuery_1.getCombinedApprovalsData)(currentUser, currentTeamId?.[0] || '', isActivityPage && showAllApprovals));
    const searchValue = (0, zustandStore_1.useZustand)(state => state.approvals.searchValue);
    const sortValue = (0, zustandStore_1.useZustand)(state => state.approvals.sortValue);
    (0, react_1.useEffect)(() => {
        // update count of all tabs
        let updatedList = filteredList;
        if (isActivityPage && showAllApprovals && approvalsData?.groupedApprovals) {
            Object.keys(approvalsData?.groupedApprovals)?.map(tab => {
                const addOnFilteredApprovals = (0, approvalHelpers_1.filterActivitiesByUserFilters)(approvalsData?.groupedApprovals[tab].approvals, filters);
                updatedList = {
                    ...updatedList,
                    [tab]: { approvals: addOnFilteredApprovals, count: addOnFilteredApprovals?.length || 0 },
                };
            });
            setFilteredList({ ...updatedList });
        }
        else {
            setFilteredList(uiActivities);
        }
    }, [filters, approvalsData?.groupedApprovals, showAllApprovals, isActivityPage]);
    const sortedAndFilteredApprovals = (0, react_1.useMemo)(() => {
        //filter and sort
        const approvalsForCurrentTab = approvalsData?.groupedApprovals[currentActiveApprovalTab]?.approvals || [];
        const filteredApprovals = (0, approvalHelpers_1.filterApprovalsByTabAndSearch)(approvalsForCurrentTab, searchValue);
        const addOnFilteredApprovals = isActivityPage && showAllApprovals
            ? (0, approvalHelpers_1.filterActivitiesByUserFilters)(approvalsForCurrentTab, filters)
            : filteredApprovals;
        const sortedApprovals = isActivityPage && showAllApprovals
            ? (0, approvalHelpers_1.sortApprovals)(addOnFilteredApprovals, slices_1.defaultApprovalSort)
            : (0, approvalHelpers_1.sortApprovals)(filteredApprovals, sortValue);
        return sortedApprovals;
    }, [filters, approvalsData?.groupedApprovals, currentActiveApprovalTab, searchValue, sortValue]);
    (0, react_1.useEffect)(() => {
        if (currentActiveApprovalTab === 'pending' &&
            sortedAndFilteredApprovals?.length &&
            !cardSources?.length &&
            isSuccess) {
            setShowCreateFwdButtons(false);
            setShowNoActiveCardInfo(true);
        }
        else {
            setShowNoActiveCardInfo(false);
        }
    }, [cardSources?.length, isSuccess, currentActiveApprovalTab, sortedAndFilteredApprovals]);
    return {
        sortedAndFilteredApprovals,
        currentActiveApprovalTab,
    };
};
exports.useApprovalsData = useApprovalsData;
