"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tradeshiftThemeConfig = void 0;
const palette_1 = require("./config/palette");
const shape_1 = require("./config/shape");
const typography_1 = require("./config/typography");
const zIndex_1 = require("./config/zIndex");
/**
 * Tradeshift MUI theme configuration. Read more at https://mui.com/material-ui/customization/theming/
 */
exports.tradeshiftThemeConfig = {
    palette: palette_1.palette,
    shape: shape_1.shape,
    typography: typography_1.typography,
    spacing: 10,
    zIndex: zIndex_1.zIndex,
};
