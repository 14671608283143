"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rootLoader = void 0;
const teamQuery_1 = require("../../queries/teamQuery");
const userQuery_1 = require("../../queries/userQuery");
const rootLoader = async ({ queryClient }) => {
    const userData = await queryClient.ensureQueryData(userQuery_1.userQuery);
    const selectedTeamId = await queryClient.ensureQueryData((0, teamQuery_1.selectedTeamIdQuery)('selectedTeamID'));
    const teams = await queryClient.ensureQueryData((0, teamQuery_1.teamsQuery)({ state: 'active' }));
    const currentTeam = await queryClient.ensureQueryData((0, teamQuery_1.teamQuery)({ teamId: selectedTeamId?.[0] || teams?.[0]?.id || '' }));
    return {
        userData,
        teams,
        selectedTeamId,
        currentTeam,
    };
};
exports.rootLoader = rootLoader;
