"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HbscTitle = exports.Title = void 0;
const material_1 = require("@mui/material");
exports.Title = (0, material_1.styled)(material_1.Typography)(({ theme }) => ({
    wordWrap: 'break-word',
    color: theme.palette.tsGoColor.black,
    margin: 0,
    fontWeight: globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
        ? theme.typography.tsFontWeight.bolder
        : theme.typography.tsFontWeight.default,
    lineHeight: theme.typography.pxToRem(30),
    boxSizing: 'border-box',
    fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.big),
    padding: `${theme.tsGoUnit.tsUnitHalf} ${theme.tsGoUnit.tsUnit}`,
}));
exports.HbscTitle = (0, material_1.styled)(material_1.Typography)(({ theme }) => ({
    wordWrap: 'break-word',
    color: theme.palette.tsGoColor.black,
    margin: 0,
    fontWeight: theme.typography.tsFontWeight.default,
    lineHeight: theme.typography.pxToRem(30),
    boxSizing: 'border-box',
    fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.medium),
    padding: `${theme.tsGoUnit.tsUnitHalf} ${theme.tsGoUnit.tsUnit}`,
}));
