"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const react_query_1 = require("@tanstack/react-query");
const date_fns_1 = require("date-fns");
const react_router_1 = require("react-router");
const userQuery_1 = require("../../../../queries/userQuery");
const dateUtils_1 = require("../../../../utils/dateUtils");
const useFormattedDate_1 = require("../hooks/useFormattedDate");
const StyledTimestamp = (0, material_1.styled)(material_1.Typography)(ownerState => {
    const { 'ts-variant': tsVariant, theme } = ownerState;
    return {
        color: theme.palette.tsGoColor.grayDark,
        paddingTop: theme.tsGoUnit.tsUnitQuarter,
        paddingBottom: theme.tsGoUnit.tsUnitQuarter,
        fontSize: theme.typography.pxToRem(theme.typography.tsFontSize.mini),
        ...(tsVariant === 'isUserAuthor' ? { alignSelf: 'flex-end' } : {}),
        ...(globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
            ? {
                fontWeight: 300,
                fontSize: '12px',
                color: '#979797',
                textTransform: 'none',
            }
            : {}),
    };
});
const Timestamp = ({ event, isCurrentUserAuthor }) => {
    const initialData = (0, react_router_1.useLoaderData)();
    const { data: currentUser } = (0, react_query_1.useQuery)({
        ...userQuery_1.userQuery,
        initialData: initialData.user,
    });
    const formattedDate = (0, useFormattedDate_1.useFormattedDate)(event.timestamp);
    const formattedDateTitle = (0, date_fns_1.format)(new Date(event.timestamp), 'Pp', {
        locale: (0, dateUtils_1.getDateFnsLocale)(currentUser?.language),
    });
    return ((0, jsx_runtime_1.jsx)(StyledTimestamp, { title: formattedDateTitle, "ts-variant": isCurrentUserAuthor ? 'isUserAuthor' : undefined, children: globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP ? `Sent ${formattedDate}` : formattedDate }));
};
exports.default = Timestamp;
