"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.streamInitialState = void 0;
exports.streamSlice = streamSlice;
const streamInitialState = () => {
    const initialAfter = Date.now();
    const initialData = { events: [], lastTimestamp: initialAfter };
    return {
        stream: {
            initialAfter,
            streamData: initialData,
            currentStreamData: initialData,
        },
    };
};
exports.streamInitialState = streamInitialState;
function streamSlice(set) {
    return {
        ...(0, exports.streamInitialState)(),
        setStreamData: (newData) => {
            set(state => {
                state.stream.streamData.events = newData.events;
                state.stream.streamData.lastTimestamp = newData.lastTimestamp || state.stream.initialAfter;
                return state;
            }, false, '[streamApi] setStreamData');
        },
        setCurrentStreamData: (newData) => {
            set(state => {
                state.stream.currentStreamData = newData;
                return state;
            }, false, '[streamApi] setCurrentStreamData');
        },
    };
}
