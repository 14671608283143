"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyledAppBar = void 0;
const material_1 = require("@mui/material");
const goTradeshiftLogo = require('../../../../../../assets/img/go-combo-mark.svg');
const hsbcLogo = require('../../../../../../assets/img/hsbc/hsbc-uk.svg');
exports.StyledAppBar = (0, material_1.styled)(material_1.AppBar)(({ theme }) => {
    const backgroundImage = globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP ? hsbcLogo.default : goTradeshiftLogo.default;
    const logoWidth = globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP ? '140px' : '210px';
    return {
        backgroundColor: theme.palette.tsGoColor.white,
        height: theme.tsGoUnit.tsUnitTriple,
        display: 'flex',
        alignContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'center',
        borderBottom: `thin solid ${theme.palette.tsGoColor.grayLight()}`,
        boxShadow: 'none',
        button: {
            right: theme.tsGoUnit.tsUnitHalf,
            position: 'absolute',
        },
        '&:before': {
            content: '""',
            backgroundColor: theme.palette.tsGoColor.white,
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: '100% 100%',
            height: theme.tsGoUnit.tsUnitDouble,
            width: logoWidth,
            marginLeft: globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP ? theme.tsGoUnit.tsUnit : theme.tsGoUnit.tsUnitHalf,
        },
    };
});
