"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const x_data_grid_1 = require("@mui/x-data-grid");
const react_query_1 = require("@tanstack/react-query");
const react_1 = require("react");
const react_router_1 = require("react-router");
const codingQuery_1 = require("../../../../../queries/codingQuery");
const teamQuery_1 = require("../../../../../queries/teamQuery");
const zustandStore_1 = require("../../../../../zustandStore");
const useColumns_1 = require("./useColumns");
const useGetTableAdditionRowEditable_1 = require("./useGetTableAdditionRowEditable");
const useGetTableData_1 = require("./useGetTableData");
const CodingTable = () => {
    const initialData = (0, react_router_1.useLoaderData)();
    const { data: codingLists = [], isFetching: isGetCodingListsFetching } = (0, react_query_1.useQuery)({
        ...(0, codingQuery_1.getCodingListsQuery)({ skipDisabled: false }),
        ...(initialData && 'codingLists' in initialData && { initialData: initialData.codingLists }),
    });
    const { data: codingEntries = [], isSuccess: isCodingEntriesSuccess } = (0, react_query_1.useQuery)((0, codingQuery_1.getCodingListsEntriesQuery)({
        codingLists,
    }));
    const { data: teams = [] } = (0, react_query_1.useQuery)((0, teamQuery_1.teamsQuery)({ state: 'active' }));
    const getTableAdditionRowEditable = (0, useGetTableAdditionRowEditable_1.useGetTableAdditionRowEditable)({
        teams,
    });
    const theme = (0, material_1.useTheme)();
    const columns = (0, useColumns_1.useColumns)();
    const { setSelectedCodingEntries } = (0, zustandStore_1.useZustand)();
    const selectedCodingList = (0, zustandStore_1.useZustand)(state => state.codingList.currentCodingListSelected);
    const selectedCodingEntries = (0, zustandStore_1.useZustand)(state => state.codingList.selectedCodingEntries);
    const [page, setPage] = (0, react_1.useState)(1);
    const LIMIT = 10; // we usually set 10 per page
    const startIndex = (page - 1) * LIMIT;
    const endIndex = startIndex + LIMIT;
    const selectedCodingEntriesData = selectedCodingEntries.values.slice(startIndex, endIndex);
    const totalCount = selectedCodingEntries.values.length || 0;
    const [tableData, isMutationInProgress] = (0, useGetTableData_1.useGetTableData)({
        teams,
        selectedCodingEntries: selectedCodingEntriesData,
    });
    const totalPages = Math.ceil(totalCount / LIMIT);
    const apiRef = (0, x_data_grid_1.useGridApiRef)();
    (0, react_1.useEffect)(() => {
        if (!apiRef.current) {
            return;
        }
        const handleCellClick = (params) => {
            if (!params.isEditable || params.cellMode !== 'view') {
                return;
            }
            apiRef.current?.startCellEditMode({
                id: params.id,
                field: params.field,
            });
        };
        if (apiRef.current && typeof apiRef.current.subscribeEvent === 'function') {
            apiRef.current.subscribeEvent('cellClick', handleCellClick);
        }
    }, [apiRef]);
    (0, react_1.useEffect)(() => {
        if (!isCodingEntriesSuccess) {
            return;
        }
        const selectedCodingEntries = codingEntries?.find(entry => entry?.code === selectedCodingList?.id);
        if (!selectedCodingEntries) {
            return;
        }
        setSelectedCodingEntries(selectedCodingEntries);
        setPage(1); // reset pager
    }, [codingEntries, isCodingEntriesSuccess, selectedCodingList]);
    return ((0, jsx_runtime_1.jsx)(material_1.Paper, { sx: { width: '100%', minWidth: '700px', overflowX: 'auto' }, children: (0, jsx_runtime_1.jsxs)(material_1.Box, { sx: { display: 'flex', flexDirection: 'column' }, children: [(0, jsx_runtime_1.jsx)(x_data_grid_1.DataGrid, { apiRef: apiRef, className: "codingListTable", columns: columns, rows: [getTableAdditionRowEditable, ...tableData], hideFooter: true, disableColumnMenu: true, loading: isGetCodingListsFetching || isMutationInProgress, onCellClick: (_params, event) => {
                        event.defaultMuiPrevented = true;
                    } }), (0, jsx_runtime_1.jsx)(material_1.Box, { sx: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingTop: theme.tsGoUnit.tsUnitHalf,
                        paddingBottom: theme.tsGoUnit.tsUnitHalf,
                    }, children: (0, jsx_runtime_1.jsx)(material_1.Pagination, { count: totalPages, page: page, onChange: (_, newPage) => {
                            setPage(newPage);
                        }, showFirstButton: true, showLastButton: true, color: "primary" }) })] }) }));
};
exports.default = CodingTable;
