"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.supportLoader = void 0;
const supportQuery_1 = require("../../../queries/supportQuery");
const supportLoader = async ({ queryClient }) => {
    const supportUrl = await queryClient.ensureQueryData(supportQuery_1.supportUrlQuery);
    return {
        supportUrl,
    };
};
exports.supportLoader = supportLoader;
