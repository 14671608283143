"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_router_1 = require("react-router");
const membersTable_1 = require("./membersTable");
const teamsTable_1 = require("./teamsTable");
const DashboardTable = () => {
    const initialData = (0, react_router_1.useLoaderData)();
    const location = (0, react_router_1.useLocation)();
    const isTeamDashboard = location.pathname.includes('/dashboard/team');
    const dashboardMetricUsers = initialData && 'dashboardMetrics' in initialData && initialData?.dashboardMetrics?.people;
    return isTeamDashboard ? ((0, jsx_runtime_1.jsx)(membersTable_1.MembersTable, { tableId: "dashboard-member-table", dashboardMetricUsers: dashboardMetricUsers })) : ((0, jsx_runtime_1.jsx)(teamsTable_1.TeamsTable, { tableId: "dashboard-team-table" }));
};
exports.default = DashboardTable;
