"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.typography = void 0;
/**
 * Typography.
 * How it works: https://mui.com/material-ui/react-typography/
 * Defaults: https://mui.com/material-ui/customization/default-theme/
 */
const letterSpacing = 'initial';
exports.typography = {
    fontFamily: '"Open Sans", system-ui',
    fontWeightLight: 400,
    fontWeightMedium: 600,
    h1: {
        fontSize: '2.25rem',
        lineHeight: '2.75rem',
        fontWeight: 600,
        letterSpacing,
    },
    h2: {
        fontSize: '1.75rem',
        lineHeight: '2.25rem',
        fontWeight: 600,
        letterSpacing,
    },
    h3: {
        fontSize: '1.5rem',
        lineHeight: '2rem',
        fontWeight: 600,
        letterSpacing,
    },
    h4: {
        fontSize: '1.25rem',
        lineHeight: '1.75rem',
        fontWeight: 600,
        letterSpacing,
    },
    h5: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
        fontWeight: 600,
        letterSpacing,
    },
    h6: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
        fontWeight: 600,
        letterSpacing,
    },
    subtitle1: {
        fontSize: '1rem',
        lineHeight: '1.75rem',
        fontWeight: 400,
        letterSpacing,
    },
    subtitle2: {
        fontSize: '0.875rem',
        lineHeight: '1.375rem',
        fontWeight: 600,
        letterSpacing,
    },
    body1: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
        fontWeight: 400,
        letterSpacing,
    },
    body2: {
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        fontWeight: 400,
        letterSpacing,
    },
    button: {
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        letterSpacing,
    },
    overline: {
        fontSize: '0.75rem',
        lineHeight: '2rem',
        fontWeight: 600,
        textTransform: 'uppercase',
        letterSpacing,
    },
    caption: {
        fontSize: '0.75rem',
        lineHeight: '1.25rem',
        fontWeight: 400,
        letterSpacing,
    },
};
