"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.muiAlertOverrides = void 0;
function getWarningStyle(theme) {
    return {
        border: theme.palette.tsGoBorder.dottedBorder,
    };
}
function getTSNoteStyle(theme) {
    return {
        color: theme.palette.tsGoColor.white,
        backgroundColor: theme.palette.tsGoColor.pinkDark,
    };
}
function getNotificationStyle(theme) {
    return {
        width: 300,
        marginRight: '10px',
        fontWeight: theme.typography.fontWeightMedium,
        backgroundColor: theme.palette.tsGoColor.white,
    };
}
exports.muiAlertOverrides = {
    styleOverrides: {
        root: ({ theme }) => ({
            border: theme.palette.tsGoBorder.noBorder,
            borderRadius: '0',
            variants: [
                {
                    props: {
                        severity: 'warning',
                    },
                    style: getWarningStyle(theme),
                },
                {
                    props: {
                        'ts-variant': 'purple-note',
                    },
                    style: getTSNoteStyle(theme),
                },
                {
                    props: {
                        'ts-variant': 'notification',
                    },
                    style: getNotificationStyle(theme),
                },
            ],
        }),
    },
};
