"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.settingsCardsLoader = void 0;
const appConstants_1 = require("../../../constants/appConstants");
const companyQuery_1 = require("../../../queries/companyQuery");
const teamQuery_1 = require("../../../queries/teamQuery");
const userQuery_1 = require("../../../queries/userQuery");
const settingsCardsLoader = async ({ queryClient }) => {
    const user = await queryClient.ensureQueryData(userQuery_1.userQuery);
    const teams = await queryClient.ensureQueryData(teamQuery_1.allTeamsQuery);
    const companyProperties = await queryClient.ensureQueryData((0, companyQuery_1.companyPropertiesQuery)(user.companyAccountId, appConstants_1.companyPropertiesConstants));
    return {
        user,
        teams,
        companyProperties,
    };
};
exports.settingsCardsLoader = settingsCardsLoader;
