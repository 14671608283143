"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const _ = __importStar(require("lodash"));
const react_1 = require("react");
const react_router_1 = require("react-router");
const react_use_1 = require("react-use");
const zustandStore_1 = require("../../../../../zustandStore");
require("./index.less");
const HeaderNavigationTab = ({ label = '', link = '', id = '', end = true, }) => {
    const NavLinkRef = (0, react_1.useRef)(null);
    const { openHeaderMenuOnMobileView, setActiveHeaderTab } = (0, zustandStore_1.useZustand)();
    const isMobileWidth = (0, react_use_1.useMedia)('(max-width: 650px)');
    (0, react_1.useEffect)(() => {
        if (!isMobileWidth) {
            openHeaderMenuOnMobileView(false);
        }
    }, [isMobileWidth, openHeaderMenuOnMobileView]);
    return ((0, jsx_runtime_1.jsx)("li", { className: 'tab-link', children: link ? ((0, jsx_runtime_1.jsx)(react_router_1.NavLink, { to: link, ref: NavLinkRef, end: end, "data-testid": label, onClick: () => {
                setActiveHeaderTab(id);
            }, style: {
                ...(globalThis.cardProvider.IS_HSBC_PROVIDER_SETUP
                    ? {
                        fontWeight: '400',
                        color: '#000000',
                    }
                    : {}),
            }, children: (0, jsx_runtime_1.jsx)("span", { children: label }) })) : null }));
};
exports.default = (0, react_1.memo)(HeaderNavigationTab, _.isEqual);
